import React from 'react';
import { Button, Circle, Image, Link } from '@chakra-ui/react';

import tgLogo from 'assets/Icons/TelegramLogo.svg';

export default function TelegramButton({ link }) {
  return (
    <Link href={link} isExternal={true}>
      <Button
        size="secondary"
        p={{ mobile: '0 18px 0 0', laptop: '0 28px 0 0' }}
        gap={{ mobile: '8px', laptop: '16px' }}
        _hover={{
          laptop: {
            gap: '17px',
            bgColor: 'bg.black',
            color: 'text.white',
            border: 'none',
            borderTop: '1.6px solid',
            '.bg-img': {
              transform: 'translate(-1px)',
              bgColor: 'bg.accent',
              img: {
                filter: 'brightness(1) invert(1)',
              },
            },
          },
        }}
      >
        <Circle className="bg-img" size={{ laptop: '64px', mobile: '40px' }} bgColor="bg.black">
          <Image
            src={tgLogo}
            boxSize={{ laptop: '32px', mobile: '18px' }}
            alt="Логотип Телеграмм"
          />
        </Circle>
        Присоединиться
      </Button>
    </Link>
  );
}
