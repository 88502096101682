import pianist from 'assets/illustrations/meetupProgramShubin_1.png';

export const meetupScheduleData = {
  title: 'Программа митапа',
  pianist,
  scheduleData: [
    { time: '18:30–19:00', description: 'Открытие. Регистрация участников' },
    {
      time: '19:00–20:00',
      description: 'Лекция «Ялтинская система от создания до распада (1945–1991)»',
      moreInfo: `В своём выступлении о Ялтинской системе международных отношений известный историк, доктор исторических наук Александр Шубин затронет следующие вопросы: <br /><br />
      1. Что такое системы международных отношений и когда они появляются? <br /><br />
      2. Каким образом в условиях завершения Второй мировой войны в 1944–1945 гг. в Москве, Ялте, Сан-Франциско и Потсдаме были достигнуты основные договорённости, которые легли в основу Ялтинской системы.<br /><br />
      3. Какую роль играла Ялтинская система в ходе событий «Холодной войны»? Какие соглашения соблюдались, а какие нет?<br /><br />
      4. Почему Ялтинская система прекратила существование? Могла ли она существовать и после завершения Холодной войны?<br /><br />
      5. Было ли обещание не расширять НАТО на Восток?<br /><br />
      6. Что «система глобализма» конца ХХ — начала XXI вв. унаследовала от Ялтинской системы? Что от Ялты может остаться в мире и после завершения текущего исторического периода.`,
    },
    {
      time: '20:00–20:30',
      description: 'Вопросы и диалоги с гостями',
    },
    {
      time: '20:30–22:00',
      description:
        'Нетворкинг и фуршет. Возможность для участников пообщаться и обменяться контактами',
    },
  ],
};
