import bgImg from 'assets/illustrations/locationShubin_1.png';

export const locationData = {
  title: 'Место проведения митапа',
  info: [
    '26 марта, 19.00 мск',
    'Арарат Парк Хаятт, ул. Неглинная, д. 4, г. Москва, Зал «Библиотека», 12 эт.',
  ],
  bgImg,
};
