import { useCallback, useEffect } from 'react';

/**
 * Хук для управления слайдами Swiper.
 *
 * @param {Object} swiper - Экземпляр Swiper.
 * @param {boolean} [slidesPerView=false] - Указывает, использовать ли динамическое количество слайдов при переходе.
 * @returns {Object} Объект с методами управления слайдами:
 *  - `slideNext`: Функция для перехода к следующему слайду. Если текущий слайд последний, переходит к первому слайду.
 *  - `slidePrev`: Функция для перехода к предыдущему слайду.
 *
 * @example
 * import { useState } from 'react';
 * import { useSwiperControls } from './useSwiperControls';
 *
 * const MyComponent = () => {
 *   const [swiper, setSwiper] = useState(null);
 *   const { slideNext, slidePrev } = useSwiperControls(swiper, true);
 *
 *   return (
 *     <Slider
 *       controller={swiper}
 *       setController={setSwiper}
 *       slidesData={data}
 *       SlideComponent={Component}
 *     />
 *   );
 * };
 */
export const useSwiperControls = (swiper, slidesPerView = false) => {
  const slideNext = useCallback(() => {
    if (swiper.isEnd) return swiper.slideTo(0);
    if (slidesPerView) {
      swiper.slideTo(swiper.activeIndex + swiper.slidesPerViewDynamic());
    } else {
      swiper.slideNext();
    }
  }, [swiper]);

  const slidePrev = useCallback(
    () =>
      slidesPerView
        ? swiper.slideTo(swiper.activeIndex - swiper.slidesPerViewDynamic())
        : swiper.slidePrev(),
    [swiper],
  );

  useEffect(() => {
    swiper && !Object.prototype.hasOwnProperty.call(swiper, 'destroyed') && swiper.slideTo(0);
  });

  return { slideNext, slidePrev };
};
