import { useTheme } from '@chakra-ui/react';
import { useLayoutEffect } from 'react';

/**
 * Хук для изменения цвета скроллбара страницы на акцентный цвет страниц Культурного кода
 */
export const useCulturalCodePageScroll = () => {
  const accentColor = useTheme().colors.culturalCode.accent;

  useLayoutEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
        body::-webkit-scrollbar-thumb {
          background-color: ${accentColor};
          border-radius: 24px;
        }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
};
