import React from 'react';
import { VStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import { isEmptyObject } from 'shared/lib/isEmptyObject';
import { usePageScrollLock } from 'shared/hooks';
import MobileNavItem from 'shared/MobileNavItem';
import CustomFade from 'shared/CustomFade';
import TabPage from 'shared/HeaderTabPage';

import { navButtonsData } from 'constants/header';
import { HEADER_BREAKPOINT, HEADER_HEIGHTS } from 'constants/global';

const HEIGHT = {
  mobile: `calc(100dvh - ${HEADER_HEIGHTS.mobile})`,
  tablet: `calc(100dvh - ${HEADER_HEIGHTS.tablet})`,
  mg: `calc(100dvh - ${HEADER_HEIGHTS.mg})`,
};

export default function HeaderMobileMenu() {
  const { isMenuOpen, headerMenuData } = useBoundStore(headerSelector);
  usePageScrollLock(isMenuOpen);

  return (
    <VStack
      w="100dvw"
      h={HEIGHT}
      maxH={HEIGHT}
      bgColor="bg.white"
      boxSizing="border-box"
      gap="16px"
      p={{ mobile: '22px 16px 45px', tablet: '12px 20px 45px' }}
      pos="fixed"
      bottom="0"
      left="0"
      zIndex="1100"
      overflow="auto"
      visibility={isMenuOpen ? 'visible' : 'hidden'}
      opacity={isMenuOpen ? '1' : '0'}
      transition="all 0.2s linear"
      hideFrom={HEADER_BREAKPOINT}
    >
      {navButtonsData.map((data, i) => (
        <MobileNavItem key={i} {...data} />
      ))}

      <CustomFade w="full" trigger={!isEmptyObject(headerMenuData)}>
        <TabPage />
      </CustomFade>
    </VStack>
  );
}
