export const headerSelector = ({
  isAnimated,
  isMenuOpen,
  isLight,
  isOverlapsFooter,
  headerMenuData,

  updateHeaderMenuState,
  updateMobileHeaderMenuState,
  updateHeaderMenuData,
  setIsAnimated,
  setIsLight,
  setIsOverlapsFooter,
}) => ({
  isAnimated,
  isMenuOpen,
  isLight,
  isOverlapsFooter,
  headerMenuData,

  updateHeaderMenuState,
  updateMobileHeaderMenuState,
  updateHeaderMenuData,
  setIsAnimated,
  setIsLight,
  setIsOverlapsFooter,
});
