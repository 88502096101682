import { useEffect, useState } from 'react';

/**
 * Хук useWindowResize отслеживает изменение ширины окна браузера
 * и возвращает текущее значение ширины.
 *
 * @returns Объект с текущей шириной окна (`width`).
 * @example const { width } = useWindowResize()
 */
export const useWindowResize = () => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width };
};
