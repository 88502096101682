import { useGlobalAudioPlayer } from 'react-use-audio-player';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useBoundStore } from 'app/store';
import { activeTrackSelector, radioSelector, radioStatusesSelector } from 'app/selectors';
import { useCustomLocations } from 'shared/hooks';

const RadioContext = createContext({});
const radioRubricName = 'ASTON Радио шоу';

export const RadioProvider = ({ children }) => {
  const [wasPlayed, setWasPlayed] = useState(false);

  const { load } = useGlobalAudioPlayer();
  const { fetchRubrics, fetchRubric } = useBoundStore(radioSelector);
  const { activeTrack, setActiveTrack, nextTrack, setNextTrack } =
    useBoundStore(activeTrackSelector);
  const { isRubricsLoaded, isRadioRubricLoaded } = useBoundStore(radioStatusesSelector);
  const { isRadioPage } = useCustomLocations();

  useEffect(() => {
    activeTrack &&
      load(activeTrack.media_url, {
        autoplay: wasPlayed,
        html5: true,
        format: 'mp3',
        initialVolume: 0.1,
        onend: () => {
          nextTrack && setNextTrack();
        },
      });
  }, [activeTrack]);

  useEffect(() => {
    if (isRadioPage) {
      !isRubricsLoaded && fetchRubrics();

      isRubricsLoaded && !isRadioRubricLoaded && fetchRubric(radioRubricName);

      if (isRadioRubricLoaded) {
        setActiveTrack(0, radioRubricName);
        setWasPlayed(true);
      }
    }
  }, [isRubricsLoaded, isRadioRubricLoaded, isRadioPage]);

  return <RadioContext.Provider value={{}}>{children}</RadioContext.Provider>;
};

export const useRadioContext = () => useContext(RadioContext);
