import React from 'react';
import DownAngle from 'assets/Icons/DownAngle.svg';
import UpAngle from 'assets/Icons/UpAngle.svg';
import RigthAngle from 'assets/Icons/RightAngle.svg';
import LeftAngle from 'assets/Icons/LeftAngle.svg';
import Cross from 'assets/Icons/Cross.svg';
import { Button, Center, Image } from '@chakra-ui/react';

const arrowsIcon = {
  right: RigthAngle,
  down: DownAngle,
  up: UpAngle,
  left: LeftAngle,
  cross: Cross,
};

export default function IconButton({
  direction = 'right',
  icon = null,
  iconProps = null,
  disableHover = false,
  isDisabled = false,
  ...props
}) {
  const iconDirection = icon || arrowsIcon[direction];

  return (
    <Button
      as={Center}
      boxSize={{ laptop: '64px', tablet: '40px', mobile: '48px' }}
      boxSizing="border-box"
      border="1.6px solid"
      borderColor="dark"
      borderRadius="50%"
      cursor="pointer"
      role="group"
      p="0"
      pos="relative"
      zIndex="2"
      transition="background-color .2s, filter .2s, transform .2s"
      _hover={disableHover ? null : { bgColor: 'bg.black' }}
      variant={isDisabled && 'disableIconBtn'}
      isDisabled={isDisabled}
      {...props}
    >
      <Image
        _groupHover={
          disableHover
            ? null
            : {
                filter: 'brightness(0) invert(1)',
              }
        }
        boxSize={{ laptop: '24px', mobile: '16px' }}
        src={iconDirection}
        alt="arrow"
        userSelect="none"
        {...iconProps}
      />
    </Button>
  );
}
