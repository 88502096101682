import React, { memo } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Button, Grid, LinkBox, LinkOverlay, Stack, Text, VStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import ExpertiseCard from '~entities/ExpertiseCard';
import TelegramButton from 'shared/TelegramButton';

import { HEADER_BREAKPOINT } from 'constants/global';

const HeaderTabPage = () => {
  const { headerMenuData, updateHeaderMenuState } = useBoundStore(headerSelector);

  const {
    title,
    text,
    btnText = null,
    btnLink = null,
    isTgLink = false,
    cardsData = null,
  } = headerMenuData;

  return (
    <Stack
      w="full"
      h="full"
      maxW={{ xxxl: '1600px', xxd: 'unset' }}
      direction={{ mobile: 'column-reverse', xxxl: 'row' }}
      gap={{ mobile: '23px', tablet: '40px', xxxl: '60px' }}
      align="start"
      justify={{ mobile: 'start', xxxl: 'space-between' }}
      pt="1px"
    >
      <Grid
        w="full"
        maxW={{ laptop: '860px', xxxl: 'unset' }}
        display={{ mobile: 'grid', xxxl: 'flex' }}
        flexWrap={{ xxxl: 'wrap' }}
        templateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)', xxxl: 'unset' }}
        autoRows={{ mobile: '160px', tablet: '180px', xxxl: 'unset' }}
        gap={{ mobile: '16px', xxxl: '20px' }}
      >
        {cardsData?.map(({ link, ...cardData }, i) => {
          const isHttp = link?.startsWith('http');

          return (
            <LinkBox as="article" key={i}>
              <LinkOverlay
                as={isHttp ? 'a' : GatsbyLink}
                {...(isHttp ? { href: link } : { to: link })}
                isExternal={isHttp}
                onClick={() => updateHeaderMenuState('close')}
              >
                <ExpertiseCard
                  {...cardData}
                  boxSize="unset"
                  w={{ mobile: 'full', xxxl: '308px', desktop: '286px' }}
                  h={{ mobile: 'full', xxxl: '264px', desktop: '286px' }}
                  p={{ desktop: '0 32px 32px 32px', mobile: '0 24px 24px 24px' }}
                  borderRadius={{ mobile: '20px', xxxl: '40px' }}
                  bgColor="bg.grayLight"
                  transition="all .2s"
                  _hover={{
                    bgColor: { [HEADER_BREAKPOINT]: 'bg.accent' },
                  }}
                  titleProps={{
                    as: 'h2',
                    textStyle: 'h1',
                    fontWeight: 500,
                  }}
                  textProps={{
                    textStyle: 'h3',
                    fontSize: { laptop: '16px', mobile: '12px' },
                  }}
                  textContainerProps={{
                    gap: { mobile: '8px', tablet: '12px', xxxl: '8px' },
                  }}
                  iconProps={{
                    top: { mobile: '8px', xxxl: '12px' },
                    left: { mobile: '8px', xxxl: '12px' },
                    p: { mobile: '13px', xxxl: '18px' },
                    boxSize: { mobile: '52px', xxxl: '72px' },
                    imageProps: {
                      boxSize: { mobile: '26px', xxxl: '36px' },
                    },
                  }}
                />
              </LinkOverlay>
            </LinkBox>
          );
        })}
      </Grid>

      <VStack
        pos={{ xxxl: 'sticky' }}
        top="0"
        align="start"
        minW={{ xxxl: '336px' }}
        maxW={{ mobile: '600px', xxxl: '336px' }}
        gap="unset"
      >
        <Text as="h2" textStyle="header.h4" pb={{ mobile: '12px', xxxl: '12px', desktop: '16px' }}>
          {title}
        </Text>
        <Text textStyle="h3" pb={(btnText || isTgLink) && { mobile: '20px', xxxl: '32px' }}>
          {text}
        </Text>
        {btnText && <Button size="secondary">{btnText}</Button>}
        {isTgLink && <TelegramButton link={btnLink} />}
      </VStack>
    </Stack>
  );
};

export default memo(HeaderTabPage);
