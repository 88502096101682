import React, { memo } from 'react';
import { HStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import NavItem from 'shared/NavItem';

import { navButtonsData } from 'constants/header';

const Navigation = props => {
  const { isLight } = useBoundStore(headerSelector);

  return (
    <HStack
      as="nav"
      gap={{ laptop: '30px', xd: '40px' }}
      flexShrink="0"
      transition="color .2s linear"
      color={`text.${!isLight ? 'black' : 'white'}`}
      userSelect="none"
      whiteSpace="nowrap"
      {...props}
    >
      {navButtonsData.map((buttonData, i) => (
        <NavItem key={i} {...buttonData} />
      ))}
    </HStack>
  );
};

export default memo(Navigation);
