/**
 * Проверяет, является ли объект пустым.
 *
 * @param {Object} obj - Объект, который нужно проверить.
 * @returns {boolean} Возвращает `true`, если объект пустой (не содержит собственных перечисляемых свойств), иначе `false`.
 *
 * @example
 * // Пример использования:
 * const emptyObj = {};
 * console.log(isEmptyObject(emptyObj)); // true
 *
 * const nonEmptyObj = { key: 'value' };
 * console.log(isEmptyObject(nonEmptyObj)); // false
 */
export const isEmptyObject = obj =>
  typeof obj === 'object' && obj !== null && !Array.isArray(obj) && Object.keys(obj).length === 0;
