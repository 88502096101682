import React, { memo } from 'react';
import { Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import UnderlinedText from 'shared/UnderlinedText';

import { EMPTY_OBJECT } from 'constants/global';

function NavItem({ text, link = null, menuData = null }) {
  const { isMenuOpen, updateHeaderMenuState, updateHeaderMenuData, isOverlapsFooter, setIsLight } =
    useBoundStore(headerSelector);
  const isHttp = link?.startsWith('http');

  return (
    <>
      {link ? (
        <Link
          as={isHttp ? 'a' : GatsbyLink}
          {...(isHttp ? { link } : { to: link })}
          w="full"
          isExternal={isHttp}
          _hover={EMPTY_OBJECT}
        >
          <NavText
            text={text}
            onMouseEnter={() => {
              updateHeaderMenuState('close');
              updateHeaderMenuData('reset');
            }}
          />
        </Link>
      ) : (
        <NavText
          text={text}
          onMouseEnter={() => {
            !isMenuOpen && updateHeaderMenuState('open');
            updateHeaderMenuData('set', menuData);
            isOverlapsFooter && setIsLight(false);
          }}
        />
      )}
    </>
  );
}

function NavText({ text, onMouseEnter }) {
  const { headerMenuData } = useBoundStore(headerSelector);
  const isActiveTab = text === headerMenuData.title;

  return (
    <UnderlinedText
      trigger={isActiveTab}
      textStyle="button.secondary"
      whiteSpace="nowrap"
      onMouseEnter={onMouseEnter}
    >
      {text}
    </UnderlinedText>
  );
}

export default memo(NavItem);
