import { getCareerLinks } from 'utils/environment';

import Company from 'assets/Icons/Company.svg';
import HandUp from 'assets/Icons/handUp.svg';
import CulturalCode from 'assets/Icons/cultural_code.png';
import Radio from 'assets/Icons/radio.svg';
import Secure from 'assets/Icons/secure.svg';
import OneC from 'assets/Icons/oneC.svg';
import Measuring from 'assets/Icons/Measuring.svg';
import plusPerson from 'assets/Icons/plusPerson.svg';
import Specialists from 'assets/Icons/specialists.svg';
import Suitcase from 'assets/Icons/suitcase.svg';
import Internship from 'assets/Icons/internship.svg';
import Course from 'assets/Icons/course.svg';
import support from 'assets/Icons/support.svg';

const { CAREER_LINK, VACANCY_LINK, TRAINEE_LINK, COURSES_LINK } = getCareerLinks();

const TITLES = {
  domainExpertise: 'Доменная экспертиза',
  businessServices: 'Услуги для бизнеса',
  projects: 'Проекты',
  career: 'Карьера',
  media: 'ASTON медиа',
  aboutUs: 'О нас',
};

export const navButtonsData = [
  {
    text: TITLES.domainExpertise,
    menuData: {
      title: TITLES.domainExpertise,
      text: 'Направления разработки, в которых у нашей команды — обширный опыт, глубокие знания и большое количество практических кейсов',
      // btnText: 'Обсудить проект',
      cardsData: [
        {
          src: Secure,
          title: 'Финтех',
          text: 'Реализуем проекты для лидеров отрасли',
          link: '/services/financial-services',
        },
        {
          src: OneC,
          title: '1С-разработка',
          text: 'Создаём решения для автоматизации бизнеса',
          link: '/1C',
        },
      ],
    },
  },
  {
    text: TITLES.businessServices,
    menuData: {
      title: TITLES.businessServices,
      text: 'Создаём цифровые решения, которые помогают решать рутинные и глобальные задачи бизнеса',
      // btnText: 'Обсудить задачу',
      cardsData: [
        {
          src: Measuring,
          title: 'Проектная разработка',
          text: 'Поиск идеально подходящей команды',
          link: '/services/managed-delivery',
        },
        {
          src: plusPerson,
          title: 'Расширение команды',
          text: 'Оперативное подключение опытных экспертов',
          link: '/services/software-team-staff-augmentation',
        },
        {
          src: Specialists,
          title: 'Выделенная команда',
          text: 'Быстрое погружение в специфику проекта',
          link: '/services/dedicated-team',
        },
        {
          src: HandUp,
          title: 'Консалтинг',
          text: 'Глубокий анализ бизнеса и рынка',
          link: '/services/it-consulting',
        },
        {
          src: support,
          title: 'Поддержка',
          text: 'Комплексная поддержка и ИТ-услуги',
          link: '/services/it-support',
        },
      ],
    },
  },
  { text: TITLES.projects, link: '/our-projects' },
  {
    text: TITLES.career,
    menuData: {
      title: TITLES.career,
      text: 'Развиваем глобальное комьюнити ИТ-профессионалов, помогаем им расти и достигать новых высот',
      isTgLink: true,
      btnLink: 'https://t.me/astontrainee',
      cardsData: [
        {
          src: Company,
          title: 'Работа в ASTON',
          text: 'Предложения от топовых компаний',
          link: CAREER_LINK,
        },
        {
          src: Suitcase,
          title: 'Вакансии',
          text: 'Для всех уровней — от Junior до Senior',
          link: VACANCY_LINK,
          tag: '44 вакансии',
        },
        {
          src: Internship,
          title: 'Стажировка',
          text: 'Актуальные знания от экспертов-практиков',
          link: TRAINEE_LINK,
          tag: '8 направлений',
        },
        {
          src: Course,
          title: 'Курсы',
          text: 'Только востребованные ИТ-профессии',
          link: COURSES_LINK,
          tag: '7 направлений',
        },
      ],
    },
  },
  {
    text: TITLES.media,
    menuData: {
      title: TITLES.media,
      text: 'Увлекательные проекты самой оптимистичной ИТ-компании',
      cardsData: [
        {
          src: CulturalCode,
          title: 'Культурный код',
          text: 'Лекции от признанных учёных России',
          link: '/cultural-code',
        },
        {
          src: Radio,
          title: 'ASTON Радио',
          text: 'Для айтишников и не только',
          link: '/radio',
        },
      ],
    },
  },
  { text: TITLES.aboutUs, link: '/about-us' },
];
