import React, { memo } from 'react';
import { Text, Box, HStack } from '@chakra-ui/react';

import { goTo } from 'shared/lib/goTo';

const CulturalCodeNavItem = ({ text, elementToGo, settings = null, onClick }) => {
  const handleButtonClick = () => () => {
    onClick();
    goTo(elementToGo, settings);
  };

  return (
    <HStack
      role="group"
      cursor="pointer"
      spacing={{ mobile: '20px', laptop: '40px' }}
      onClick={handleButtonClick()}
    >
      <Box
        w={{ mobile: '27.5px', tablet: '55px', laptop: '120px', desktop: '240px' }}
        h={{ mobile: '14px', laptop: '28px' }}
        transition="all 0.2s"
        bgColor="culturalCode.accent"
        _groupHover={{
          w: { mobile: '27.5px', tablet: '110px', laptop: '240px', desktop: '480px' },
        }}
      />
      <Text
        as="h2"
        fontSize={{ mobile: '24px', tablet: '36px', laptop: '64px' }}
        color="text.white"
      >
        {text}
      </Text>
    </HStack>
  );
};

export default memo(CulturalCodeNavItem);
