import React, { memo } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Text, Link, HStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import { isEmptyObject } from 'shared/lib/isEmptyObject';
import IconButton from 'shared/IconButton';
import CustomFade from 'shared/CustomFade';

import { EMPTY_OBJECT } from 'constants/global';

const MobileNavItem = ({ text, link = null, menuData = null }) => {
  const isHttp = link?.startsWith('http');
  const { headerMenuData, updateHeaderMenuData, updateHeaderMenuState } =
    useBoundStore(headerSelector);
  const isHeaderMenuDataEmpty = isEmptyObject(headerMenuData);

  return (
    <CustomFade trigger={isHeaderMenuDataEmpty} w="full">
      {link ? (
        <Link
          as={isHttp ? 'a' : GatsbyLink}
          {...(isHttp ? { link } : { to: link })}
          w="full"
          isExternal={isHttp}
          _hover={EMPTY_OBJECT}
          onClick={() => updateHeaderMenuState('close')}
        >
          <Item text={text} menuData={menuData} />
        </Link>
      ) : (
        <Item
          text={text}
          menuData={menuData}
          onClick={() => updateHeaderMenuData('set', menuData)}
        />
      )}
    </CustomFade>
  );
};

const Item = ({ text, menuData, ...props }) => (
  <HStack
    w="full"
    minH={{ mobile: '64px', tablet: '80px' }}
    align="center"
    justify="space-between"
    p={{ mobile: '16px', tablet: '16px 20px' }}
    borderRadius="12px"
    bgColor="bg.grayLight"
    cursor="pointer"
    {...props}
  >
    <Text as="h2" textStyle="header.h4">
      {text}
    </Text>

    {menuData && (
      <IconButton
        disableHover
        border="none"
        boxSize={{ mobile: '32px', tablet: '48px' }}
        iconProps={{ boxSize: { mobile: '24px', tablet: '32px' } }}
      />
    )}
  </HStack>
);

export default memo(MobileNavItem);
