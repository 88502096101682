import React from 'react';
import { Text } from '@chakra-ui/react';

export default function LozungCapsText({ children, ...props }) {
  return (
    <Text
      fontFamily="Lozung Caps, sans-serif"
      fontWeight="400"
      lineHeight="100%"
      letterSpacing="-0.04em"
      color="culturalCode.accent"
      {...props}
    >
      {children}
    </Text>
  );
}
