import React from 'react';
import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
		@font-face {
			font-display: swap;
			font-family: "Inter";
			font-weight: 400;
			font-style: normal;
			src:
				local("Inter-Regular"),
				url("/fonts/Inter/Inter-Regular.ttf") format("truetype");
		}
		@font-face {
			font-display: swap;
			font-family: "Inter";
			font-weight: 450;
			font-style: normal;
			src:
				local("Inter-MidRegular"),
				url("/fonts/Inter/Inter-MidRegular.ttf") format("truetype");
		}
		@font-face {
			font-display: swap;
			font-family: "Inter";
			font-weight: 500;
			font-style: normal;
			src:
				local("Inter-Medium"),
				url("/fonts/Inter/Inter-Medium.ttf") format("truetype");
		}
		@font-face {
			font-display: swap;
			font-family: "Lozung Caps";
			font-weight: 400;
			font-style: normal;
			src: 
				local("Lozung Caps Black"),
				url("/fonts/Lozung Caps/Lozung Caps Black.otf") format("opentype");
		}
      `}
  />
);
