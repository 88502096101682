import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { Link as GatsbyLink } from 'gatsby';
import { Flex, HStack, Img, Link } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import Navigation from '~entities/CulturalCodeNavigation';
import { useCustomLocations } from 'shared/hooks';
import LozungCapsText from 'shared/LozungCapsText';
import MenuToggle from 'shared/MenuToggle';

import logo from 'assets/CulturalCode/logo.svg';
import { SHUBIN_HEADER_BREAKPOINT } from 'constants/global';

const CulturalCodeHeader = () => {
  const { isMenuOpen } = useBoundStore(headerSelector);
  const { isShubinPage } = useCustomLocations();

  return (
    <Flex
      id="header"
      as={motion.header}
      initial={false}
      animate={isMenuOpen ? 'open' : 'closed'}
      pos="fixed"
      top="0"
      left="0"
      zIndex="1200"
      w="100vw"
      justify="center"
      bgColor="culturalCode.headerBg"
      backdropFilter="blur(40px)"
      boxSizing="border-box"
    >
      <Flex
        w="full"
        maxW="2060px"
        justify="space-between"
        p={{ mobile: '20px 16px', tablet: '17px 20px', laptop: '17px 40px', desktop: '17px 80px' }}
        gap="20px"
      >
        <Link as={GatsbyLink} to="/cultural-code">
          <HStack align="start" spacing={{ mobile: '8px', tablet: '13px' }}>
            <Img
              src={logo}
              alt="Логотип"
              objectFit="contain"
              boxSize={{ mobile: '24px', tablet: '38px' }}
            />
            <LozungCapsText
              mt={{ mobile: '7px', tablet: '12px' }}
              color="text.white"
              fontSize={{ mobile: '14px', tablet: '22px' }}
            >
              Культурный код
            </LozungCapsText>
          </HStack>
        </Link>

        <MenuToggle isCulturalCode={true} hideFrom={isShubinPage && SHUBIN_HEADER_BREAKPOINT} />
        <Navigation />
      </Flex>
    </Flex>
  );
};

export default memo(CulturalCodeHeader);
