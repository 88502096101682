const breakpoints = {
  mobile: '0px',
  xm: '373px',
  xms: '400px',
  xxm: '544px',
  tablet: '656px',
  md: '742px',
  mg: '885px',
  lg: '960px',
  laptop: '992px',
  xl: '1024px',
  xxl: '1200px',
  xxxl: '1350px',
  preDesktop: '1435px',
  desktop: '1441px',
  xd: '1700px',
  xxd: '1900px',
};

export default breakpoints;
