import { Center, Image } from '@chakra-ui/react';
import React, { memo } from 'react';

const SchevronIcon = ({ src = null, imageProps = null, ...props }) => {
  return (
    <Center
      borderRadius="full"
      boxSizing="border-box"
      bgColor="white"
      overflow="hidden"
      boxSize={{ desktop: '72px', tablet: '36px', mobile: '' }}
      {...props}
    >
      <Image src={src} objectFit="contain" {...imageProps} />
    </Center>
  );
};
export default memo(SchevronIcon);
