import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import { usePageScrollLock } from 'shared/hooks';
import { isEmptyObject } from 'shared/lib/isEmptyObject';
import TabPage from 'shared/HeaderTabPage';

import { DISABLE_SCROLLBAR_STYLE, HEADER_BREAKPOINT, HEADER_HEIGHTS } from 'constants/global';

export default function HeaderMenu() {
  const menuRef = useRef(null);
  const [menuHeight, setMenuHeight] = useState(0);
  const {
    isMenuOpen,
    headerMenuData,
    updateHeaderMenuState,
    updateHeaderMenuData,
    isOverlapsFooter,
    setIsLight,
  } = useBoundStore(headerSelector);
  const isHeaderTabOpen = isMenuOpen && !isEmptyObject(headerMenuData);
  usePageScrollLock(isHeaderTabOpen);

  useEffect(() => {
    const calculateMenuHeight = () => {
      if (menuRef.current) {
        setMenuHeight(menuRef.current.offsetHeight);
      }
    };

    calculateMenuHeight();
    window.addEventListener('resize', calculateMenuHeight);

    return () => {
      window.removeEventListener('resize', calculateMenuHeight);
    };
  }, [headerMenuData]);

  return (
    <>
      <Flex
        ref={menuRef}
        w="100dvw"
        maxH={{
          mobile: `calc(80dvh - ${HEADER_HEIGHTS.mobile})`,
          tablet: `calc(80dvh - ${HEADER_HEIGHTS.tablet})`,
          mg: `calc(80dvh - ${HEADER_HEIGHTS.mg})`,
        }}
        justify="end"
        pos="fixed"
        top={isHeaderTabOpen ? HEADER_HEIGHTS : `-${menuHeight}px`}
        left="0"
        zIndex="1100"
        bgColor="bg.white"
        borderBottomRadius="48px"
        boxSizing="border-box"
        transition="top .6s"
        p={{ laptop: '24px 40px 40px', desktop: '24px 40px 64px', xxd: '24px 40px 64px 260px' }}
        overflow="auto"
        hideBelow={HEADER_BREAKPOINT}
        sx={DISABLE_SCROLLBAR_STYLE}
      >
        <TabPage />
      </Flex>

      <Box
        w="100dvw"
        h="100dvh"
        pos="fixed"
        top="0"
        left="0"
        zIndex="1000"
        bgColor="#0d0e0c26"
        backdropFilter={isHeaderTabOpen ? 'blur(7px)' : 'unset'}
        opacity={isHeaderTabOpen ? '1' : '0'}
        visibility={isHeaderTabOpen ? 'visible' : 'hidden'}
        transition="all .6s"
        onMouseEnter={() => {
          if (isHeaderTabOpen) {
            updateHeaderMenuState('close');
            setTimeout(() => updateHeaderMenuData('reset'), 600);
            isOverlapsFooter && setIsLight(true);
          }
        }}
        hideBelow={HEADER_BREAKPOINT}
      />
    </>
  );
}
