import { useLocation } from '@reach/router';

/**
 * Хук `useCustomLocations` определяет текущее состояние маршрута в приложении.
 *
 * Он возвращает объект с булевыми значениями, указывающими,
 * находитесь ли вы на одной из определенных страниц:
 * - `isRadioPage`: true, если текущий путь равен '/radio'.
 * - `isCulturalCodePages`: true, если текущий путь равен '/cultural-code'
 *   или '/cultural-code/20thcentury'.
 * - `isCulturalCodePage`: true, если текущий путь равен '/cultural-code'.
 * - `isShubinPage`: true, если текущий путь равен '/cultural-code/20thcentury'.
 *
 * @returns {{ isCulturalCodePages: boolean, isCulturalCodePage: boolean, isShubinPage: boolean, isRadioPage: boolean }}
 */
export const useCustomLocations = () => {
  const pathname = useLocation().pathname;

  const isRadio = pathname === '/radio';
  const isCulturalCode = pathname === '/cultural-code';
  const isShubin = pathname === '/cultural-code/20thcentury';
  const allPagesCondition = isCulturalCode || isShubin;

  return {
    isRadioPage: isRadio,
    isCulturalCodePages: allPagesCondition,
    isCulturalCodePage: isCulturalCode,
    isShubinPage: isShubin,
  };
};
