export const EMPTY_OBJECT = {};

export const FORM_ID = 'contact-form';

export const RADIO_TG_LINK = 'https://t.me/radioaston';
export const ASTON_INSTAGRAM_LINK = 'https://www.instagram.com/aston.it/';

export const HEADER_BREAKPOINT = 'xxl';
export const SHUBIN_HEADER_BREAKPOINT = 'xxl';
export const HEADER_HEIGHTS = { mobile: '52px', tablet: '88px', mg: '102px' };
export const GREETINGS_GAP = { mobile: '180px', tablet: '200px', laptop: '210px' };

export const DISABLE_SCROLLBAR_STYLE = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
};
