import { motion } from 'framer-motion';
import { Link as GatsbyLink } from 'gatsby';
import { Flex, Link } from '@chakra-ui/react';
import React, { forwardRef, useEffect, useRef, memo } from 'react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import HeaderMobileMenu from 'features/HeaderMobileMenu';
import HeaderMenu from 'features/HeaderMenu';
import Navigation from '~entities/Navigation';
import { isEmptyObject } from 'shared/lib/isEmptyObject';
import GoBackButton from 'shared/HeaderGoBackButton';
import MenuToggle from 'shared/MenuToggle';
import CustomFade from 'shared/CustomFade';
import Logo from 'shared/Logo';

import { HEADER_BREAKPOINT, HEADER_HEIGHTS } from 'constants/global';

const HEADER_PADDING = { mg: '20px 40px', tablet: '11px 20px', mobile: '3px 20px' };

const Header = (_, ref) => {
  const raf = useRef(null);
  const { isMenuOpen, isAnimated, isLight, setIsAnimated, headerMenuData } =
    useBoundStore(headerSelector);
  const isHeaderTabOpen = isMenuOpen && !isEmptyObject(headerMenuData);
  const animatedColor = isAnimated ? 'rgba(255, 255, 255, 0.40)' : 'transparent';

  useEffect(() => {
    function spyHeader() {
      if (window.scrollY >= 20) {
        setIsAnimated(true);
      } else {
        setIsAnimated(false);
      }
      raf.current = requestAnimationFrame(spyHeader);
    }
    raf.current = requestAnimationFrame(spyHeader);
    return () => cancelAnimationFrame(raf.current);
  }, []);

  return (
    <>
      <Flex
        ref={ref}
        id="header"
        as={motion.header}
        initial={false}
        animate={isMenuOpen ? 'open' : 'closed'}
        w="100vw"
        minH={HEADER_HEIGHTS}
        pos="fixed"
        top="0"
        left="0"
        zIndex="1200"
        align="center"
        justify={{ mobile: 'space-between', [HEADER_BREAKPOINT]: 'unset' }}
        gap={{ mobile: '15px', tablet: '20px', mg: '40px', desktop: '76px' }}
        boxSizing="border-box"
        p={HEADER_PADDING}
        bgColor={{
          mobile: isMenuOpen ? 'bg.white' : animatedColor,
          [HEADER_BREAKPOINT]: isHeaderTabOpen ? 'bg.white' : animatedColor,
        }}
        borderStyle="solid"
        borderBottomWidth={isAnimated && !isMenuOpen ? '1.6px' : '0px'}
        borderColor={`text.${isLight ? 'white' : 'black'}`}
        backdropFilter="blur(20px)"
        transition="border-width .1s linear, background-color .2s linear"
      >
        <CustomFade
          trigger={!isHeaderTabOpen}
          unmountOnExit={false}
          transition={!isHeaderTabOpen && { delay: 0.6 }}
          hideFrom={HEADER_BREAKPOINT}
        >
          <LogoWithLink />
        </CustomFade>

        <LogoWithLink hideBelow={HEADER_BREAKPOINT} />

        <Navigation hideBelow={HEADER_BREAKPOINT} />

        <MenuToggle hideFrom={HEADER_BREAKPOINT} />
      </Flex>

      <Flex
        minH={HEADER_HEIGHTS}
        pos="fixed"
        top="0"
        left="0"
        zIndex="1300"
        align="center"
        p={isHeaderTabOpen && HEADER_PADDING}
        transitionDelay={!isHeaderTabOpen && '1s'}
        hideFrom={HEADER_BREAKPOINT}
      >
        <CustomFade trigger={isHeaderTabOpen}>
          <GoBackButton />
        </CustomFade>
      </Flex>

      <HeaderMenu />

      <HeaderMobileMenu />
    </>
  );
};

const LogoWithLink = props => (
  <Link as={GatsbyLink} to="/" {...props}>
    <Logo
      w={{ mobile: '82px', tablet: '102px', desktop: '164px' }}
      h={{ mobile: '16px', tablet: '20px', desktop: '32px' }}
    />
  </Link>
);

export default memo(forwardRef(Header));
