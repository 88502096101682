import { EMPTY_OBJECT } from 'constants/global';

export const createHeaderSlice = (set, get) => ({
  isAnimated: false,
  isLight: false,
  isOverlapsFooter: false,
  isMenuOpen: false,

  headerMenuData: {},

  updateHeaderMenuState(action) {
    switch (action) {
      case 'toggle':
        set({ isMenuOpen: !get().isMenuOpen });
        break;
      case 'open':
        set({ isMenuOpen: true });
        break;
      case 'close':
        set({ isMenuOpen: false });
        break;
      default:
        throw Error(`Такой action не поддерживается: ${action}`);
    }
  },
  updateHeaderMenuData(action, newMenuData = null) {
    switch (action) {
      case 'set':
        set({ headerMenuData: newMenuData });
        break;
      case 'reset':
        set({ headerMenuData: EMPTY_OBJECT });
        break;
      default:
        throw Error(`Такой action не поддерживается: ${action}`);
    }
  },
  setIsAnimated(bool) {
    set({ isAnimated: bool });
  },
  setIsLight(bool) {
    set({ isLight: bool });
  },
  setIsOverlapsFooter(bool) {
    set({ isOverlapsFooter: bool });
  },
});
