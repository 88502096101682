import zen from 'assets/Icons/zen_red.svg';
import rutube from 'assets/Icons/rutube_red.svg';
import youtube from 'assets/People/Icons/youtube_red.svg';

export const modalButtonsData = [
  {
    icon: youtube,
    text: 'YouTube',
    link: 'http://www.youtube.com/@%D0%9A%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%BD%D1%8B%D0%B9_%D0%9A%D0%BE%D0%B4/featured',
    iconProps: {
      boxSize: { mobile: '32px', laptop: '52px' },
    },
    buttonProps: {
      p: { mobile: '12px 32px', laptop: '10px 44px' },
    },
  },
  {
    icon: rutube,
    text: 'RuTube',
    link: 'https://rutube.ru/channel/44655132/',
    iconProps: {
      w: { mobile: '32px', laptop: '52px' },
      h: { mobile: '36px', laptop: '59px' },
    },
    buttonProps: {
      p: { mobile: '10px 32px', laptop: '6.5px 44px' },
    },
  },
  {
    icon: zen,
    text: 'Яндекс.Дзен',
    link: 'https://dzen.ru/kulturniykod',
    iconProps: {
      boxSize: { mobile: '36px', laptop: '60px' },
    },
    buttonProps: {
      p: { mobile: '10px 32px', laptop: '6.5px 44px' },
    },
    containerProps: { transform: 'translate(15px)' },
  },
];
