import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { Box, VStack } from '@chakra-ui/react';

import { headerSelector } from 'app/selectors';
import { useBoundStore } from 'app/store';
import { isEmptyObject } from 'shared/lib/isEmptyObject';

import { menuToggleVariants } from 'constants/header';

const MenuToggle = ({ isCulturalCode = false, ...props }) => {
  const {
    isLight,
    isOverlapsFooter,
    setIsLight,
    headerMenuData,
    updateHeaderMenuState,
    updateHeaderMenuData,
  } = useBoundStore(headerSelector);
  const isHeaderMenuDataFully = !isEmptyObject(headerMenuData);

  return (
    <VStack
      boxSize="32px"
      justify="space-between"
      zIndex="1100"
      cursor="pointer"
      onClick={() => {
        updateHeaderMenuState('toggle');
        !isCulturalCode && isHeaderMenuDataFully && updateHeaderMenuData('reset');
        isOverlapsFooter && setIsLight(!isLight);
      }}
      {...props}
    >
      <Box
        as={motion.svg}
        width="100%"
        height="100%"
        paddingLeft="2px"
        paddingTop="5px"
        viewBox="0 0 23 23"
        objectFit="cover"
        stroke={`text.${isCulturalCode ? 'white' : isLight ? 'white' : 'black'}`}
      >
        {menuToggleVariants?.map((val, i) => (
          <Path key={i} {...val} />
        ))}
      </Box>
    </VStack>
  );
};

const Path = props => (
  <Box as={motion.path} fill="transparent" strokeWidth="2" strokeLinecap="round" {...props} />
);

export default memo(MenuToggle);
