import React from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

export default function OfficeInfo({ city, address, dotStyles = null }) {
  return (
    <Grid
      templateColumns={{ laptop: '12px auto', xxm: '6px auto' }}
      templateRows={{ desktop: '34px auto', laptop: '28px auto', xxm: '22px auto' }}
      gap={{ laptop: '12px', xxm: '8px' }}
    >
      <Box
        borderRadius="full"
        placeSelf="center"
        boxSize={{ laptop: '12px', xxm: '6px' }}
        bgColor="bg.accent"
        {...dotStyles}
      />
      <Flex align="center" textStyle="h1" lineHeight={{ laptop: '140%', tablet: '120%' }}>
        {city}
      </Flex>
      <Text
        textStyle="h3"
        gridColumn={2}
        gridRow={2}
        color="#A7A9A5"
        dangerouslySetInnerHTML={{ __html: address }}
        fontSize={{ laptop: '16px', tablet: '12px' }}
      />
    </Grid>
  );
}
