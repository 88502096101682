exports.components = {
  "component---src-pages-1-c-index-jsx": () => import("./../../../src/pages/1C/index.jsx" /* webpackChunkName: "component---src-pages-1-c-index-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/AboutUs/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-bodyshop-index-jsx": () => import("./../../../src/pages/Bodyshop/index.jsx" /* webpackChunkName: "component---src-pages-bodyshop-index-jsx" */),
  "component---src-pages-confident-index-jsx": () => import("./../../../src/pages/Confident/index.jsx" /* webpackChunkName: "component---src-pages-confident-index-jsx" */),
  "component---src-pages-consulting-index-jsx": () => import("./../../../src/pages/Consulting/index.jsx" /* webpackChunkName: "component---src-pages-consulting-index-jsx" */),
  "component---src-pages-cookies-index-jsx": () => import("./../../../src/pages/Cookies/index.jsx" /* webpackChunkName: "component---src-pages-cookies-index-jsx" */),
  "component---src-pages-cultural-code-index-jsx": () => import("./../../../src/pages/CulturalCode/index.jsx" /* webpackChunkName: "component---src-pages-cultural-code-index-jsx" */),
  "component---src-pages-dedicated-team-index-jsx": () => import("./../../../src/pages/DedicatedTeam/index.jsx" /* webpackChunkName: "component---src-pages-dedicated-team-index-jsx" */),
  "component---src-pages-fintech-index-jsx": () => import("./../../../src/pages/Fintech/index.jsx" /* webpackChunkName: "component---src-pages-fintech-index-jsx" */),
  "component---src-pages-index-page-index-jsx": () => import("./../../../src/pages/IndexPage/index.jsx" /* webpackChunkName: "component---src-pages-index-page-index-jsx" */),
  "component---src-pages-mds-index-jsx": () => import("./../../../src/pages/MDS/index.jsx" /* webpackChunkName: "component---src-pages-mds-index-jsx" */),
  "component---src-pages-personal-data-index-jsx": () => import("./../../../src/pages/PersonalData/index.jsx" /* webpackChunkName: "component---src-pages-personal-data-index-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/Projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-radio-page-index-jsx": () => import("./../../../src/pages/RadioPage/index.jsx" /* webpackChunkName: "component---src-pages-radio-page-index-jsx" */),
  "component---src-pages-ru-404-jsx": () => import("./../../../src/pages-ru/404.jsx" /* webpackChunkName: "component---src-pages-ru-404-jsx" */),
  "component---src-pages-ru-about-us-index-jsx": () => import("./../../../src/pages-ru/about-us/index.jsx" /* webpackChunkName: "component---src-pages-ru-about-us-index-jsx" */),
  "component---src-pages-ru-confidentiality-index-jsx": () => import("./../../../src/pages-ru/confidentiality/index.jsx" /* webpackChunkName: "component---src-pages-ru-confidentiality-index-jsx" */),
  "component---src-pages-ru-cookies-index-jsx": () => import("./../../../src/pages-ru/cookies/index.jsx" /* webpackChunkName: "component---src-pages-ru-cookies-index-jsx" */),
  "component---src-pages-ru-cultural-code-20-thcentury-index-jsx": () => import("./../../../src/pages-ru/cultural-code/20thcentury/index.jsx" /* webpackChunkName: "component---src-pages-ru-cultural-code-20-thcentury-index-jsx" */),
  "component---src-pages-ru-cultural-code-index-jsx": () => import("./../../../src/pages-ru/cultural-code/index.jsx" /* webpackChunkName: "component---src-pages-ru-cultural-code-index-jsx" */),
  "component---src-pages-ru-index-js": () => import("./../../../src/pages-ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-ru-our-projects-index-jsx": () => import("./../../../src/pages-ru/our-projects/index.jsx" /* webpackChunkName: "component---src-pages-ru-our-projects-index-jsx" */),
  "component---src-pages-ru-personal-data-index-jsx": () => import("./../../../src/pages-ru/personal-data/index.jsx" /* webpackChunkName: "component---src-pages-ru-personal-data-index-jsx" */),
  "component---src-pages-ru-radio-index-jsx": () => import("./../../../src/pages-ru/radio/index.jsx" /* webpackChunkName: "component---src-pages-ru-radio-index-jsx" */),
  "component---src-pages-ru-services-dedicated-team-index-jsx": () => import("./../../../src/pages-ru/services/dedicated-team/index.jsx" /* webpackChunkName: "component---src-pages-ru-services-dedicated-team-index-jsx" */),
  "component---src-pages-ru-services-financial-services-index-jsx": () => import("./../../../src/pages-ru/services/financial-services/index.jsx" /* webpackChunkName: "component---src-pages-ru-services-financial-services-index-jsx" */),
  "component---src-pages-ru-services-index-jsx": () => import("./../../../src/pages-ru/services/index.jsx" /* webpackChunkName: "component---src-pages-ru-services-index-jsx" */),
  "component---src-pages-ru-services-it-consulting-index-jsx": () => import("./../../../src/pages-ru/services/it-consulting/index.jsx" /* webpackChunkName: "component---src-pages-ru-services-it-consulting-index-jsx" */),
  "component---src-pages-ru-services-it-support-index-jsx": () => import("./../../../src/pages-ru/services/it-support/index.jsx" /* webpackChunkName: "component---src-pages-ru-services-it-support-index-jsx" */),
  "component---src-pages-ru-services-managed-delivery-index-jsx": () => import("./../../../src/pages-ru/services/managed-delivery/index.jsx" /* webpackChunkName: "component---src-pages-ru-services-managed-delivery-index-jsx" */),
  "component---src-pages-ru-services-software-team-staff-augmentation-index-jsx": () => import("./../../../src/pages-ru/services/software-team-staff-augmentation/index.jsx" /* webpackChunkName: "component---src-pages-ru-services-software-team-staff-augmentation-index-jsx" */),
  "component---src-pages-shubin-index-jsx": () => import("./../../../src/pages/Shubin/index.jsx" /* webpackChunkName: "component---src-pages-shubin-index-jsx" */),
  "component---src-pages-support-index-jsx": () => import("./../../../src/pages/Support/index.jsx" /* webpackChunkName: "component---src-pages-support-index-jsx" */)
}

