import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

/**
 * Компонент `CustomFade` обеспечивает анимацию появления и исчезновения дочерних элементов с эффектом затухания.
 * Позволяет управлять монтированием и размонтированием контента через проп `unmountOnExit`.
 *
 * param {boolean} `trigger` - Определяет, должен ли компонент быть видимым.
 *
 * param {React.ReactNode} `children` - Дочерние элементы, которые будут анимироваться.
 *
 * param {boolean} `unmountOnExit`=true - Если `true`, компонент удаляется из DOM при исчезновении.
 *
 * param {Object} `transition` - Дополнительные параметры анимации Framer Motion.
 *
 * param `...props` - Любые другие свойства, поддерживаемые `Box` из Chakra UI.
 *
 * @returns Анимированный компонент или `null`, если `unmountOnExit` включён и `trigger` равен `false`.
 * @example
 * // Анимированное появление элемента
 * <CustomFade trigger={isVisible}>
 *   <Box>Контент</Box>
 * </CustomFade>
 */
export default function CustomFade({
  trigger,
  children,
  unmountOnExit = true,
  transition = null,
  ...props
}) {
  const [shouldRender, setShouldRender] = useState(trigger);

  useEffect(() => {
    let timeout;
    if (trigger) {
      timeout = setTimeout(() => {
        setShouldRender(true);
      }, 600);
    }

    return () => clearTimeout(timeout);
  }, [trigger]);

  if (!shouldRender && unmountOnExit) return null;

  return (
    <Box {...props}>
      <motion.div
        style={{ width: '100%' }}
        initial={{ opacity: 0, x: -15 }}
        animate={trigger ? { opacity: 1, x: 0 } : { opacity: 0, x: -15 }}
        transition={{ duration: 0.3, ease: 'linear', ...transition }}
        onAnimationComplete={() => {
          if (!trigger) {
            setShouldRender(false);
          }
        }}
      >
        {children}
      </motion.div>
    </Box>
  );
}
