import { useEffect } from 'react';

/**
 * Кастомный хук для блокировки прокрутки страницы.
 *
 * @param {boolean} isLocked - Флаг, указывающий, нужно ли блокировать прокрутку.
 *
 * @example
 * usePageScrollLock(isModalOpen);
 *
 * Если `isLocked = true`, то прокрутка страницы будет заблокирована,
 * если `isLocked = false`, то прокрутка будет разблокирована.
 */
export const usePageScrollLock = isLocked => {
  useEffect(() => {
    if (isLocked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isLocked]);
};
