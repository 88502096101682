import { useState, useEffect } from 'react';

/**
 * Откладывает обновление значения на заданное время.
 *
 * @param {any} value - Значение, которое нужно задержать.
 * @param {number} delay - Задержка в миллисекундах перед обновлением значения.
 * @returns {any} debouncedValue - Значение, обновляемое с задержкой.
 *
 * @example
 * const debouncedValue = useDebounce(value, 500);
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
