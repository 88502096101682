import { appendScriptInnerHtml, appendScriptSrc, appendNoscriptInnerHtml } from 'utils/scripts';
import { getIsSsr } from 'utils/environment';
import {
  recaptchSrc,
  loadedScriptsOnLoad,
  loadedScriptsOnScroll,
  yandexMetrika,
  yandexMetrikaNoscript,
  mailMetrika,
  mailMetrikaNoscript,
  metaMetrika,
  metaMetrikaNoscript,
  googleMetrika,
  googleMetrikaSrc,
  // widgetSrc,
} from 'define/scripts';
require('lazysizes');
require('lazysizes/plugins/attrchange/ls.attrchange');
require('./static/entry-points');
require('./static/gclid');

const appendScriptsOnScrollOptions = { once: true };

const appendScriptsOnLoad = () => {
  if (getIsSsr()) {
    appendScriptInnerHtml(yandexMetrika);
    appendScriptInnerHtml(mailMetrika);
    appendScriptInnerHtml(metaMetrika);
    appendScriptInnerHtml(googleMetrika);
  }
  appendNoscriptInnerHtml(yandexMetrikaNoscript);
  appendNoscriptInnerHtml(mailMetrikaNoscript);
  appendNoscriptInnerHtml(metaMetrikaNoscript);
  appendScriptSrc(recaptchSrc, 'body');
  appendScriptSrc(googleMetrikaSrc, 'head');

  sessionStorage.setItem(loadedScriptsOnLoad, 'true');
};

const appendScriptsOnScroll = () => {
  appendScriptSrc(recaptchSrc, 'body');

  sessionStorage.setItem(loadedScriptsOnScroll, 'true');
};

export const onClientEntry = () => {
  window.addEventListener('load', appendScriptsOnLoad);
};

export const onInitialClientRender = () => {
  window.addEventListener('scroll', appendScriptsOnScroll, appendScriptsOnScrollOptions);
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 0);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 0);
  }
  return false;
};

export const wrapPageElement = require(`./src/app/PageLayout`).default;
export const wrapRootElement = require(`./src/app/RootWrapper`).default;

export const disableCorePrefetching = () => true;
