export const vacancyCardsData = [
  {
    id: 1,
    title: `Go Developer`,
    description: `Заказчик – крупная российская компания, которая предоставляет клиентам цифровые, медийные и телекоммуникационные сервисы`,
    isHot: true,
  },
  {
    id: 2,
    title: `Java Developer`,
    description: `Заказчик входит в топ-10 российских банков по величине активов, кредитного портфеля, размеру средств, привлеченных от клиентов, и нормативного капитала.`,
    isHot: true,
  },
  {
    id: 3,
    title: `Python Developer`,
    description: `Заказчик – одна из крупнейших IT-компаний России, которая развивает самую популярную в стране поисковую систему и создает сервисы для миллионов людей.`,
    isHot: true,
  },
  {
    id: 4,
    title: `QA Automation Engineer (Java)`,
    description: `Заказчик – финансовый конгломерат, крупнейший универсальный банк России. Самый дорогой российский бренд и самый сильный банковский бренд в мире по версии Brand Finance.`,
    isHot: false,
  },
  {
    id: 5,
    title: `Python Developer`,
    description: `Заказчик – крупный российский продуктовый ритейлер, лидер офлайн- и онлайн-рынка продуктов питания. Компания управляет портфелем брендов сетевых магазинов, цифровыми бизнесами, собственными службами логистики и несколькими цифровыми сервисами для..`,
    isHot: false,
  },
  {
    id: 6,
    title: `Junior IT Sourcer`,
    description: `IT-компания ASTON - компания по разработке программного обеспечения c многолетним опытом, предоставляющая широкий спектр услуг в сфере IT - проводит набор на стажировку по направлению IT Sourcer Trainee с последующим трудоустройством.`,
    isHot: false,
  },
  {
    id: 7,
    title: `QA Automation Engineer (Java)`,
    description: `Заказчик входит в топ-10 российских банков по величине активов, кредитного портфеля, размеру средств, привлеченных от клиентов, и нормативного капитала.`,
    isHot: false,
  },
  {
    id: 8,
    title: `C++ Developer`,
    description: `Заказчик – российский разработчик офисного ПО для одновременной работы с разными типами документов, обмена сообщениями и хранения файлов. Компания работает на рынке 9 лет, офисы в Москве, Санкт-Петербурге, Иннополисе и Самаре.`,
    isHot: false,
  },
  {
    id: 9,
    title: `QA Engineer fullstack`,
    description: `Заказчик – российский коммерческий банк, один из крупнейших интернет-банков страны. Глобальная цель на данном этапе – уйти от бюрократии, долгих нецелесообразных банковских процессов и заменить их на надежный и удобный мобильный банкинг.`,
    isHot: false,
  },
  {
    id: 10,
    title: `Business Analyst`,
    description: `Заказчик входит в топ-10 российских банков по величине активов, кредитного портфеля, размеру средств, привлеченных от клиентов, и нормативного капитала.`,
    isHot: false,
  },
];
