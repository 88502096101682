import { ASTON_INSTAGRAM_LINK, RADIO_TG_LINK } from 'constants/global';

import tg_green from 'assets/People/Icons/tg_green.svg';
import inst_green from 'assets/People/Icons/inst_green.svg';
import youtube_green from 'assets/People/Icons/youtube_green.svg';
import vk_green from 'assets/People/Icons/vk_green.svg';
import tg_red from 'assets/People/Icons/tg_red.svg';
import inst_red from 'assets/People/Icons/inst_red.svg';
import youtube_red from 'assets/People/Icons/youtube_red.svg';
import vk_red from 'assets/People/Icons/vk_red.svg';

export const socialMedias = [
  {
    href: RADIO_TG_LINK,
    src: tg_green,
    alt: 'tg',
  },
  {
    href: ASTON_INSTAGRAM_LINK,
    src: inst_green,
    alt: 'inst',
    isBlocked: true,
  },
  {
    href: 'https://www.youtube.com/@astonlive2023',
    src: youtube_green,
    alt: 'youtube',
  },
  {
    href: 'https://vk.com/aston.company',
    src: vk_green,
    alt: 'vk',
  },
];
export const culturalCodeSocialMedias = [
  {
    href: RADIO_TG_LINK,
    src: tg_red,
    alt: 'tg',
  },
  {
    href: ASTON_INSTAGRAM_LINK,
    src: inst_red,
    alt: 'inst',
    isBlocked: true,
  },
  {
    href: 'https://www.youtube.com/@astonlive2023',
    src: youtube_red,
    alt: 'youtube',
  },
  {
    href: 'https://vk.com/aston.company',
    src: vk_red,
    alt: 'vk',
  },
];

export const offices = [
  {
    city: 'г. Москва',
    address: `Пресненская наб., 6, стр. 2 <br/>
    БЦ «Башня Империя», 45 и 50 эт. <br/>
    Россия`,
  },
  {
    city: 'г. Санкт-Петербург',
    address: `Владимирский пр-т, 23 <br />
    БЦ «Ренессанс Холл», 6 и 8 эт. <br/>
    Россия`,
  },
  {
    city: 'г. Казань',
    address: `ул. Н. Ершова, 76/1, оф. 219 <br/>
    Россия`,
  },
  {
    city: 'г. Ростов-на-Дону',
    address: `ул. Текучева, 246, 3 эт. <br/>
    Россия`,
  },
  {
    city: 'г. Нижний Новгород',
    address: `ул. Новая, д. 28, коворкинг «VMESTE», 5 эт. <br/> 
    Россия`,
  },
  {
    city: 'г. Самара',
    address: `Московское ш. 4к4, IT-парк Монте Роза <br/>
    Россия`,
  },
  {
    city: 'г. Алматы',
    address: `пр. Гагарина, 124б, коворкинг Sail <br/>
    Казахстан`,
  },
  {
    city: 'г. Минск',
    address: `пр-т Победителей, 7А, <br/>
    БЦ «Royal Plaza», 23 и 25 эт. <br/>
    Беларусь`,
  },
  {
    city: 'г. Витебск',
    address: `Московский пр-т, 70к1 <br/>
    Беларусь`,
  },
  {
    city: 'г. Полоцк',
    address: `ул. Октябрьская, 25 <br/>
    Беларусь`,
  },

  {
    city: 'г. Гомель',
    address: `ул. Советская, 41Б <br/>
    Беларусь`,
  },
];
