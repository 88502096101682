import { STORAGE_URL_ASTON_REDESIGN } from 'constants/Api/constants';

const commonAssets = {
  favicon: `${STORAGE_URL_ASTON_REDESIGN}/common/favicon/AstonFavicon.svg`,
  logo: {
    Aston: {
      light: `${STORAGE_URL_ASTON_REDESIGN}/common/logo/AstonLogo_light.svg`,
      dark: `${STORAGE_URL_ASTON_REDESIGN}/common/logo/AstonLogo_dark.svg`,
    },
  },
  icons: {
    Aston: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/AstonIcon.svg`,
    Burger: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Burger.svg`,
    Check: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Check.svg`,
    Cross: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Cross.svg`,
    Minus: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Minus.svg`,
    Plus: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Plus.svg`,
    Phone: {
      default: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Phone.svg`,
      black: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Phone_black.svg`,
    },
    Mail: {
      default: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Mail.svg`,
      green: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Mail_green.svg`,
      black: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/Mail_black.svg`,
    },
    Angle: {
      down: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/DownAngle.svg`,
      left: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/LeftAngle.svg`,
      up: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/UpAngle.svg`,
      right: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/RightAngle.svg`,
    },
    Arrow: {
      down: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/DownArrow.svg`,
      up: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/UpArrow.svg`,
      left: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/LeftArrow.svg`,
      right: `${STORAGE_URL_ASTON_REDESIGN}/common/icons/RightArrow.svg`,
    },
  },
};

export default commonAssets;
