import shubinImg from 'assets/illustrations/greetingsShubin_1.png';
import line from 'assets/illustrations/greetingsShubin_2.png';
import illustration from 'assets/illustrations/greetingsShubin_3.png';

export const greetingsData = {
  subtitle:
    'Закрытая лекция от доктора исторических наук, профессора А. Шубина. Поговорим про особенности Ялтинской системы и её влияние на современные международные отношения',
  tags: ['26 марта, 19.00 мск', 'Москва, Арарат Парк Хаятт'],
  shubinImg,
  illustration,
  line,
};
